.work_menu li a {
    cursor: pointer;
    display: block;
    color: #fff;
    border-radius: 30px;
    letter-spacing: 0.03em;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 16px;
    transition: all 0.5s;
    margin-top: 5px;
}

.work_menu a.active,
.work_menu a:hover {
    color: #fff !important;
}

.work_item {
    position: relative;
    padding: 15px;
}

.work_box {
    overflow: hidden;
    position: relative;
}

.work_box .work_img {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
}

.work_box .work_img:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.work_box .work_img>img {
    transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.work_box .work_detail {
    opacity: 0;
    color: #ffffff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
}

.work_box .work_detail h4 {
    font-size: 18px;
}

.work_box .work_detail p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
}

.work_box:hover .work_detail {
    top: 50%;
    transform: translate(0, -50%);
    opacity: 1;
}

.work_box:hover .work_img:after {
    background: rgba(38, 40, 43, 0.72);
}

.work_box:hover .work_img>img {
    transform: scale(1.05);
}